import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Grid, Collapse, Modal } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ViewOption from "../components/ViewOption";
import HeavyTable from "../components/table/HeavyTable";
import DeleteModal from "../components/modal/DeleteModal";
import {
  makeSureIsArray,
  resetAll,
  changeViewOptions,
  controlView,
  controlFilter,
  cancelAction,
} from "../functions/general";
import { showWarningSwal } from "../functions/alert";
import { getTasks } from "../functions/getData";
import { deleteTask } from "../functions/deleteData";
import { showNotification } from "../functions/snackbar";
import AddTaskToCompetitionAndCollectionModal from "../components/modal/AddTaskToCompetitionAndCollectionModal";
import NunitoText from "../components/general/NunitoText";
import ReusableTextField from "../components/general/ReusableTextField";
import ReusableButton from "../components/general/ReusableButton";
import ViewAndFilterButton from "../components/ViewAndFilterButton";
import LoadingBackdrop from "../components/general/LoadingBackdrop";
import NoData from "../components/general/NoData";
import Loader from "../components/general/Loader";
import { useLocation } from "react-router-dom";

import { useSnackbar } from "notistack";
import DuplicateTask from "../components/modal/DuplicateTask";
import { duplicatetasks } from "../functions/postData";
import FilterWrapper from "../components/general/FiltersWrapper";
const taskHeader = [
  { id: "identifier", label: "Task Identifier" },
  { id: "title", label: "Task Title" },
  { id: "status", label: "Status" },
  { id: "description", label: "Description" },
  { id: "languages", label: "No. of Language(s)" },
  { id: "domain", label: "Domain" },
  { id: "tags", label: "Tags" },
  { id: "grade_difficulty", label: "Recommended Difficulty" },
  { id: "answer_type", label: "Answer Type" },
  { id: "answer_structure", label: "Answer Structure" },
  { id: "answer_sorting", label: "Display Order" },
  { id: "task_answers", label: "No. of Correct Answers" },
  // { id: 'collections', label: 'Collections' },
  // { id: 'competitions', label: 'Competitions' },
  { id: "created_by", label: "Created By" },
  { id: "last_modified_by", label: "Last Modified By" },
];
const initialParams = "?limit=10";
export default function EnhancedTasks() {
  document.title = "Task";
  const isMounted = useRef(false);
  const [header, setHeader] = useState(taskHeader);
  const [taskTable, setTaskTable] = useState();
  const [viewOptions, setViewOptions] = useState([
    { label: "No. of Langauge(s)", state: true, key: "task_content" },
    { label: "Domain", state: true, key: "domain" },
    { label: "Tags", state: true, key: "tags" },
    {
      label: "Recommended Difficulty",
      state: true,
      key: "recommended_difficulty",
    },
    { label: "Answer Type", state: true, key: "answer_type" },
    { label: "Answer Structure", state: true, key: "answer_structure" },
    { label: "Display Order", state: true, key: "display_order" },
    { label: "No. of answers", state: true, key: "answers" },
    { label: "Collections", state: true, key: "collections" },
    { label: "Competitions", state: true, key: "competitions" },
    { label: "Created By", state: true, key: "created_by_username" },
    { label: "Last Modified By", state: true, key: "modified_by_username" },
    {
      label: "Last Verified By(Last 5)",
      state: true,
      key: "verified_by_username",
    },
    { label: "Status", state: true, key: "status" },
  ]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [params, setParams] = useState(initialParams);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false); 
  const [viewing, setViewing] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [id, setId] = useState("");
  const [selected, setSelected] = useState([]);
  const [selecting, setSelecting] = useState([]);
  const [onDelete, setOnDelete] = useState(false);
  const [onAddTask, setOnAddTask] = useState(false);
  const [onDup, setOnDups] = useState(false);
  const [onAssignTaskPermission, setOnAssignTaskPermission] = useState(false);
  const [language , setLanguage] = useState('')
  const [domain , setdomain] = useState('')
  const [tag , settag] = useState('')
  const [status , setstatus] = useState('')

  const handleView = () => controlView(viewing, setViewing, setFiltering);
  const handleFilter = () => controlFilter(filtering, setFiltering, setViewing);
  const cancelDelete = () => cancelAction(setOnDelete, setId);
  const cancelAddTask = () => cancelAction(setOnAddTask);
  const cancelAssignTaskPermission = () =>
    cancelAction(setOnAssignTaskPermission);
  const canceldubs = () => cancelAction(setOnDups);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const afterFunction = (cancel, data) => {
    if ([200, 201].includes(data.status)) {
      resetAll(initialParams, '', setParams, setSearch, '');
      getData(initialParams, signal);
      cancel();
      showNotification("success", data.message, enqueueSnackbar, closeSnackbar);
      setSelected([]);
      setSelecting([]);
    }
  };
  const adjustEndpoint = (endpoint , value)=>{
    let initalEndpoint = endpoint
    if(value!==language&&language!==''){
      initalEndpoint+=`&languages=${language}`
    }
    if(value!==domain&&domain!==''){
      initalEndpoint+=`&domains=${domain}`
    }
    if(value!==tag&&tag!==''){
      initalEndpoint+=`&tags=${tag}`
    }
    if(value!==status&&status!==''){
      initalEndpoint+=`&status=${status}`
    }
   return initalEndpoint

  }
  const filters =[{
    label:"Language",
    endpoint:"tasks/list?get_filter=languages",
    value:language,
    onChange:setLanguage,
    key:'languages',
    setValue:setLanguage
  },{
    label:"Domain",
    endpoint:"tasks/list?get_filter=domains",
    value:domain,
    onChange:setdomain,
    key:'domains',
    setValue:setdomain
  },
  {
    label:"Tag",
    endpoint:"tasks/list?get_filter=tags",
    value:tag,
    onChange:settag,
    key:'tags',
    setValue:settag
  },
  {
    label:"Status",
    endpoint:"tasks/list?get_filter=status",
    value:status,
    onChange:setstatus,
    key:'status',
    setValue:setstatus
  }]
  const deleteFunction = () => {
    setLoading(true);
    deleteTask({ id: selected.length > 0 ? selected : [id] })
      .then((d) => {
        afterFunction(cancelDelete, d);
      })
      .catch((e) => console.log(e))
      .finally(() => isMounted.current && setLoading(false));
  };
  const getData = (params, signal) => {
    setSelected([]);
    setSelecting([]);
    setLoading(true);
    let param=params+''
    if(search!=='') param+=`search=${search}`
    filters.map((filter)=>{
        if(filter.value!=='') param+=`&${filter.key}=${filter.value}`
    })
    getTasks(`${param}` , signal).then(res=>{
       console.log(res)
       setTaskTable(res)
       setLoading(false)
    })
  };
  useEffect(()=>{
getData(params, signal)
  },[domain, status,tag,language])
  console.log(taskTable);
  const onChangeViewOptions = (value) =>
    changeViewOptions(taskHeader, setViewOptions, setHeader, value);
 
  const duplicate = () => {
    duplicatetasks(id).then((d) => {
      console.log(d);
      afterFunction(canceldubs, d);
    });
  };
  
  const onChangeSearch = (value) => {
    setSearch(value)
    if (JSON.stringify(value) === JSON.stringify(search)) return console.log('Search is unchanged')
        if(value!==''){
    getData(params+`&search=${value}` , signal)}else{
        getData(params , signal)
    }
  };
  const onChangePage = (pageNo) => {
    getData(`${params}&page=${pageNo}`, signal)};
  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;
  let location = useLocation();

  useEffect(() => {
    isMounted.current = true;
    if (location.search.slice(0, 4) === "?id=") {
      getData(initialParams + `&collection_id=${location.search.slice(4)}`);
    } else {
      getData(initialParams, signal);
    }
    return () => {
      controller.abort();
      isMounted.current = false;
    };
  }, [controller, signal]);
  const onClickAddTaskToCompetitionAndCollection = () => {
    return selected.length
      ? setOnAddTask(true)
      : showWarningSwal("Please select a task to add to collection");
  };
  const onClickMassAssignTaskPermissions = () => {
    return selected.length
      ? setOnAssignTaskPermission(true)
      : showWarningSwal("Please select a task to assign permissions for");
  };
  const onClickMassDelete = () => {
    return selected.length
      ? setOnDelete(true)
      : showWarningSwal("Please select at least one task to mass delete");
  };
  
  return (
    <Box className="wrapperBox">
      <LoadingBackdrop loading={loading} />
      <Collapse in={viewing}>
        <ViewOption
          viewOptions={viewOptions}
          rowsPerPage={rowsPerPage}
          onChangeViewOptions={onChangeViewOptions}
        />
      </Collapse>
      <Collapse in={filtering}>
      <FilterWrapper
        filters={filters}
        adjustEndpoint={adjustEndpoint}
        />
     
      </Collapse>
      <Grid
        className="firstRowContainer"
        container
        justifyContent="space-between"
      >
        <div className="dashboardAndSelfBtnDiv">
          <ReusableButton
            text="Dashboard"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            width={125}
            br={18}
            to="/dashboard"
            iconType="home"
          />
          <ChevronRightIcon />
          <ReusableButton
            text="Tasks"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            br={18}
          />
        </div>
        <div className="viewAndFilterBtnDiv">
          <ViewAndFilterButton
            text="View Options"
            state={viewing}
            fontSize={14}
            height={42}
            onClick={handleView}
            marginRight={10}
          />
          <ViewAndFilterButton
            text="Filter"
            state={filtering}
            fontSize={14}
            height={42}
            onClick={handleFilter}
          />
        </div>
      </Grid>
      <Grid className="tableContainer" container>
        <NunitoText
          value="Tasks"
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
        />
        <Grid
          className="searchAndBtnContainer"
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <ReusableTextField
            type="search"
            width={500}
            height={60}
            bgColor="#F2F2F2"
            placeholder="Search using keyword"
            state={search}
            setState={onChangeSearch}
            onBlur
          />
          <div className="endBtnDiv">
            <ReusableButton
              text="Add Task"
              fontSize={15}
              bgColor="#5E75C3"
              height={46}
              marginRight={20}
              to="/addtask"
              iconType="add"
            />
            <ReusableButton
              text="Mass Delete"
              fontSize={15}
              bgColor="#E83042"
              height={46}
              marginRight={20}
              onClick={() => onClickMassDelete()}
              iconType="delete"
            />
          </div>
        </Grid>
        <NunitoText
          value='Search only in "Task Identifier" and "Task Title" column'
          fontSize={20}
          fontWeight={400}
          italic
          color="#144A94"
        />
        {loadingTable && <Loader height={600} />}
        {!loadingTable &&
          taskTable &&
          makeSureIsArray(taskTable.data).length && (
            <HeavyTable
              headers={header}
              list={taskTable}
              title="task"
              rowsPerPage={rowsPerPage}
              setOnDups={setOnDups}
              setOnDelete={setOnDelete}
              onChangePage={onChangePage}
              fixed={["identifier", "title"]}
              selecting={selecting}
              setSelecting={setSelecting}
              selected={selected}
              setSelected={setSelected}
              setId={setId}
            />
          )}
        {!loadingTable &&
        
          taskTable &&
          !makeSureIsArray(taskTable.data).length && <NoData height={600} />}
      </Grid>
      <Modal open={onDelete} onClose={() => cancelDelete()}>
        <>
          {Boolean(taskTable) && (
            <DeleteModal
              table={taskTable}
              id={id}
              selected={selected}
              deleteFunction={deleteFunction}
              cancelDelete={cancelDelete}
            />
          )}
        </>
      </Modal>
      <Modal open={onAddTask} onClose={() => cancelAddTask()}>
        <>
          {Boolean(taskTable) && (
            <AddTaskToCompetitionAndCollectionModal
              afterAddTask={() => {}}
              tasks={taskTable.data}
              selected={selected}
              setSelected={setSelected}
              cancelAddTask={cancelAddTask}
            />
          )}
        </>
      </Modal>
      <Modal
        open={onAssignTaskPermission}
        onClose={() => cancelAssignTaskPermission()}
      >
        <div className="popUpModal horizontalScrollable">
          <h1>Placeholder modal for assigning task permissions</h1>
        </div>
      </Modal>
      <Modal open={onDup} onClose={() => canceldubs()}>
        <>
          {Boolean(taskTable) && (
            <DuplicateTask
              canceldub={canceldubs}
              id={id}
              duplicate={duplicate}
              table={taskTable}
            />
          )}
        </>
      </Modal>
    </Box>
  );
}
