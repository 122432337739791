import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  IconButton,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  Chip,
  Modal,
  Box,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { swapIndex } from "../../functions/patchData";
import {
  formatDate,
  makeSureIsArray,
  dateHasPassed,
  gradeOptions,
} from "../../functions/general";
import { isAdmin } from "../../functions/checkrole";
import { getComparator, stableSort } from "../../functions/table";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedHover from "./EnhancedHover";
import EnhancedPagination from "./EnhancedPagination";
import ActionButtons from "./ActionButtons";
import NunitoText from "../general/NunitoText";
import ReusableSelect from "../general/ReusableSelect";
import LightTooltip from "../general/LightTooltip";
import useWindowDimensions from "../../hooks/WindowDimensions";
import { func } from "prop-types";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
const chipStyle = {
  backgroundColor: "#F16774",
  color: "#FFF",
  marginRight: 3,
  border: "0.5px solid gray",
};
const divStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
  minWidth: 150,
  maxWidth: 300,
};
const noellipseStyle = {
  whiteSpace: "nowrap",
  display: "block",
  overflow: "hidden",
  minWidth: 150,
};
const topicWrapperStyle = { textAlign: "center", padding: "10px 4px" };
const centerFlexStyle = { display: "flex", alignItems: "center" };
const DisplayChipCell = ({ row, getter, title }) => {
  let arr = [
    "task",
    "add task to collection",
    "tasks",
    "competitions",
  ].includes(title)
    ? row.tags && row.tags.filter((t) => t.is_tag || t.is_tag === undefined)
    : row[getter];
  return (
    <div style={{ display: "flex" }}>
      {arr &&
        arr.map((tag, index) =>
          index < 4 ? (
            index === 3 ? (
              <LightTooltip
                title={<Topics title="Tags" topics={arr.slice(3)} />}
                rollover={1}
                topic={1}
                hide={0}
              >
                <Chip
                  key={index}
                  style={chipStyle}
                  label={`+${arr.length - index}`}
                />
              </LightTooltip>
            ) : (
              <Chip
                key={index}
                style={chipStyle}
                label={
                  [
                    "task",
                    "add task to collection",
                    "collection",
                    "compCollection",
                    "tasks",
                    "collections",
                    "competitions",
                  ].includes(title)
                    ? tag.name
                    : tag
                }
              />
            )
          ) : null
        )}
    </div>
  );
};
const IndexDropdown = ({ row, getter, onChangePage, list }) => {
  const [index, setIndex] = useState(row[getter]);
  const onChange = (e) => {
    swapIndex({ index: index, indexToSwap: e }).then((d) => {
      if (![200, 201].includes(d.status)) return;
      setIndex(e);
      onChangePage(1);
    });
  };
  // Filter out from all indexes to form the available options for the index by
  // checking for competition name, country id,
  // if is school candidate then compare both school id and tuition centre id,
  // if is private candidate then just compare tuition centre id
  const indexOptions = makeSureIsArray(list.data)
    .filter(
      (d) =>
        d.country_id === row.country_id &&
        d.competition_name === row.competition_name &&
        (row.private
          ? d.tuition_centre_id === row.tuition_centre_id
          : d.school_id === row.school_id &&
            d.tuition_centre_id === row.tuition_centre_id)
    )
    .map((d) => ({ index: d.index_no, name: d.name }));
  return (
    <div style={divStyle}>
      <ReusableSelect
        type="type2"
        state={index}
        setState={onChange}
        btnBgColor="#144A94"
        height={45}
        width={280}
        options={indexOptions.map((i) => ({
          value: i.index,
          option: `${i.index} - ${i.name}`,
        }))}
      />
    </div>
  );
};
const Topics = ({ title, topics }) => {
  return (
    <div style={topicWrapperStyle}>
      <NunitoText value={title} fontSize={18} marginBottom={10} />
      {topics.map((t, i) => (
        <NunitoText key={i} value={t.name} color="#000" fontSize={16} />
      ))}
    </div>
  );
};
const Cell = ({
  setOnVerfiy,
  setOnDups,
  count,
  row,
  getter,
  isItemSelected,
  color,
  fixed,
  widths,
  title,
  setPartcipient,
  setIssingle,
  setOnReasons,
  collection,
  section,
  taskInSection,
  checkTask,
  onHoverRejectReason,
  list,
  getters,
  setId,
  setOnDelete,
  setOnApprove,
  setOnReject,
  setOnRestore,
  setOnEdit,
  setOnModerateLanguages,
  setOnManagePermission,
  setOnAddUser,
  setOnAddParticipant,
  setOnEnable,
  setOnDisable,
  setOnAdd,
  setOnUpload,
  setOnEditParticipantRanking,
  setConfirmMarking
}) => {
  const tableCellStyle = {
    paddingLeft: 15,
    backgroundColor: isItemSelected ? "#EDF4FB" : color,
    backgroundClip: "padding-box",
  };
  const fixedTableCellStyle = (getter) => {
    let index = widths.findIndex((w) => w.id === getter);
    let w = widths[index - 1]?.totalWidth || 0;
    return {
      paddingLeft: 15,
      backgroundColor: isItemSelected ? "#EDF4FB" : color,
      backgroundClip: "padding-box",
      position: "sticky",
      left: w,
      zIndex: 999,
    };
  };
  let output = row[getter] || "-";
  let hoverData = row[getter];
  let hideCondition;

  if (["participants"].includes(title) && getter === "grade") {
    output = gradeOptions.find((g) => g.id === Number(row[getter])).name;
  } else if (
    ["participants"].includes(title) &&
    getter == "is_answers_uploaded"
  ) {
    output = row[getter] === 1 ? "yes" : "no";
  } else if (
    ["organization"].includes(title) &&
    ["phone", "users"].includes(getter)
  ) {
    switch (getter) {
      case "phone":
        output = row[getter]
          .replaceAll(",", "/")
          .replaceAll('"', "")
          .replace("[", "")
          .replace("]", "");
        break;
      case "users":
        output = row[getter].filter((r) => r.role_id === 2).length;
        hoverData = row[getter].filter((r) => r.role_id === 2);
        break;
      default:
        break;
    }
  } else if (["reject_reason", "format", "private"].includes(getter)) {
    switch (getter) {
      case "reject_reason":
        output = row[getter].length ? (
          <p
            onClick={() => onHoverRejectReason(row[getter])}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {row[getter].length}
          </p>
        ) : (
          <p>{row[getter].length}</p>
        );
        break;
      case "format":
        output = row[getter] ? "global" : "local";
        break;
      case "private":
        output = row[getter] ? "Yes" : "No";
        break;
      default:
    }
  } else if (
    [
      "task",
      "add task to collection",
      "collection",
      "compCollection",
      "collections",
      "tasks",
    ].includes(title) &&
    getter === "domain"
  ) {
    let domains = row.tags.filter((t) => t.is_tag === 0);
    let finaloutput = [];
    domains.map((bigEl) => {
      let exsistingwihtsamedomain = 0;
      finaloutput.map((el) => {
        if (bigEl.domain_id === el.domain_id) {
          exsistingwihtsamedomain++;
        }
      });
      if (exsistingwihtsamedomain === 0) {
        finaloutput.push(bigEl);
      }
    });
    output = (
      <div style={{ display: "flex", flexWrap: "wrap", width: 300 }}>
        {finaloutput.map((domain, i) => {
          let topics = row.tags.filter((t) => t.is_tag === 0);
          return (
            <LightTooltip
              key={i}
              title={<Topics title="Topics" topics={topics} />}
              rollover={1}
              topic={1}
              hide={!topics.length ? 1 : 0}
            >
              <div>
                <NunitoText
                  value={`${domain.topic_domain}${
                    i === finaloutput.length - 1 ? "" : `,\u00a0`
                  }`}
                  fontSize={16}
                />
              </div>
            </LightTooltip>
          );
        })}
      </div>
    );
  } else if (
    ["pending partners competition date"].includes(title) &&
    ["registration_open_date", "competition_date"].includes(getter)
  ) {
    if (getter === "competition_date") {
      output = `${new Date(row[getter][0]).toLocaleDateString()}${
        row[getter].length > 1 ? "..." : ""
      }`;
      hoverData = row[getter].slice(1);
    } else if (getter === "registration_open_date") {
      let regDay = new Date(row.registration_open_date);
      let lastDay = new Date(
        new Date(row.competition_date[0]).setDate(
          new Date(row.competition_date[0]).getDate() -
            row.registration_days_apart
        )
      );
      output = (
        <p style={{ margin: 0 }}>
          <span
            style={{
              color: dateHasPassed(lastDay, regDay) ? "#D96474" : "#6FC9B6",
            }}
          >
            {regDay.toLocaleDateString()}
          </span>{" "}
          -
          <span
            style={{ color: dateHasPassed(lastDay, new Date()) && "#D96474" }}
          >
            {lastDay.toLocaleDateString()}
          </span>
        </p>
      );
    }
  } else if (
    ["domain"].includes(title) &&
    ["domain_name", "name", "tag"].includes(getter)
  ) {
    // Handle domain, topic and tag
    if (getter === "tag") {
      if (row.is_tag === 1) output = row.name;
      else output = "-";
    } else if (getter === "domain_name") {
      if (row.is_tag === 0 && row.domain_name === null) output = row.name;
      else output = row[getter];
    } else {
      if (row.domain_name !== null) output = row.name;
      else output = "-";
    }
  } else if (
    ["created", "approved", "modified", "approved", "rejected"].some((c) =>
      getter.includes(c)
    )&&title!=='collection'
  ) {
    // Handle who and when of creation and modification of records
    let date = "";
    if (getter.includes("created")) {
      if (row.created_at) date = formatDate(row.created_at, "/");
    } else {
      if (row.updated_at) date = formatDate(row.updated_at, "/");
    }
    if (title === "users") {
      output = row[getter]!==undefined?`${row[getter].split(" ")[0]} ${date}`:`${row[getter]} ${date}`;
    } else {
      output = `${row[getter]} ${date}`;
    }
  } else if (["add task to collection"].includes(title) && getter === "title") {
    if (checkTask(collection, row.id) !== -1) {
      hoverData = {
        content: `Task already added to Section ${
          checkTask(collection, row.id) + 1
        }`,
      };
    }
  } else if ([null, undefined, ""].includes(row[getter])) {
    output = "-";
  } else if (typeof row[getter] === "object") {
    if (
      getter === "task_answers" &&
      row.answer_type === "mcq" &&
      row.answer_structure !== "sequence"
    ) {
      hoverData = row[getter].filter((a) => a.answer === "1");
      output = hoverData.length;
    } else if (getter === "teachers" && row.teachers.length) {
      let to = !isAdmin()
        ? `/users?status=active&role_id=3&school_id=${row.id}`
        : `/users?status=active&role_id=3&school_id=${row.id}&country_id=${row.country_id}`;
      output = <Link to={to}>{row[getter].length}</Link>;
    } else if (getter === "partners" && row.partners.length && isAdmin()) {
      let to = `/users?status=active&role_id=2`;
      output = <Link to={to}>{row[getter].length}</Link>;
    } else output = row[getter].length;
  } else if (title === "editround" && getter === "assigned_award_id") {
    output = row[getter];
  } else if (title === "editround" && getter === "reference_award") {
    output = row[getter];
  } else if (getter === "description") {
    if (row[getter] === "<p>-</p>") {
      output = row[getter].slice(3, row[getter].length - 4);
    }
  }

  hideCondition =
    output === "-" ||
    count === 1 ||
    ["reject_reason"].includes(getter) ||
    ([
      "task",
      "add task to collection",
      "collection",
      "compCollection",
      "collections",
      "tasks",
      "competitions",
    ].includes(title) &&
      getter === "domain") ||
    getter === "tags" ||
    (title === "editround" && String(row[getter]).length < 38);
  return (
    <LightTooltip
      title={EnhancedHover({
        getter,
        data: hoverData,
        type: row.answer_type,
        structure: row.answer_structure,
        title,
        isObject: typeof hoverData === "object",
        output,
      })}
      rollover={typeof hoverData === "object" ? 1 : 0}
      topic={0}
      hide={hideCondition ? 1 : 0}
    >
      <TableCell
        align="left"
        component="th"
        scope="row"
        style={
          fixed.includes(getter) ? fixedTableCellStyle(getter) : tableCellStyle
        }
      >
        {count === 1 ? (
          <div style={noellipseStyle}>
            {output}
            <ActionButtons
              setOnVerfiy={setOnVerfiy}
              setOnDups={setOnDups}
              setIssingle={setIssingle}
              title={title}
              row={row}
              collection={collection}
              section={section}
              taskInSection={taskInSection}
              setPartcipient={setPartcipient}
              setOnReasons={setOnReasons}
              checkTask={checkTask}
              getters={getters}
              setId={setId}
              setOnDelete={setOnDelete}
              setOnApprove={setOnApprove}
              setOnReject={setOnReject}
              setOnRestore={setOnRestore}
              setOnEdit={setOnEdit}
              setOnModerateLanguages={setOnModerateLanguages}
              setOnManagePermission={setOnManagePermission}
              setOnAddUser={setOnAddUser}
              setOnAddParticipant={setOnAddParticipant}
              setOnEnable={setOnEnable}
              setOnDisable={setOnDisable}
              setOnAdd={setOnAdd}
              setOnUpload={setOnUpload}
              setOnEditParticipantRanking={setOnEditParticipantRanking}
              setConfirmMarking={setConfirmMarking}
            />
          </div>
        ) : getter === "tags" ? (
          <DisplayChipCell row={row} getter={getter} title={title} />
        ) : getter === "language" ? (
          ["task", "taskModeration"].includes(title) ? (
            <div style={divStyle}>{output}</div>
          ) : (
            <DisplayChipCell row={row} getter={getter} title={title} />
          )
        ) : (
          <div style={divStyle}>{output}</div>
        )}
      </TableCell>
    </LightTooltip>
  );
};
const Row = ({
  getters,
  row,
  isItemSelected,
  fixed,
  widths,
  title,
  setPartcipient,
  setIssingle,
  setOnReasons,
  setOnDups,
  setOnVerfiy,
  collection,
  section,
  taskInSection,
  checkTask,
  onHoverRejectReason,
  list,
  setId,
  setOnDelete,
  setOnApprove,
  setOnReject,
  setOnRestore,
  setOnEdit,
  setOnModerateLanguages,
  setOnManagePermission,
  setOnAddUser,
  setOnAddParticipant,
  setOnEnable,
  setOnDisable,
  setOnAdd,
  setOnUpload,
  setOnEditParticipantRanking,
  setConfirmMarking
}) => {
  const [color, setColor] = useState("#FFF");
  const tableRowStyle = {
    height: 80,
    overFlow: "auto",
  };
  return (
    <TableRow
      selected={isItemSelected}
      style={tableRowStyle}
      onMouseEnter={() => setColor("#F6F6F6")}
      onMouseLeave={() => setColor("#FFF")}
    >
      {getters.map(
        (getter, index) =>
          !["id"].includes(getter) && (
            <Cell
              key={index}
              count={index}
              row={row}
              getter={getter}
              isItemSelected={isItemSelected}
              color={color}
              fixed={fixed}
              setPartcipient={setPartcipient}
              setIssingle={setIssingle}
              setOnReasons={setOnReasons}
              widths={widths}
              title={title}
              collection={collection}
              section={section}
              taskInSection={taskInSection}
              checkTask={checkTask}
              setOnVerfiy={setOnVerfiy}
              onHoverRejectReason={onHoverRejectReason}
              list={list}
              setId={setId}
              setOnDelete={setOnDelete}
              setOnApprove={setOnApprove}
              setOnReject={setOnReject}
              setOnRestore={setOnRestore}
              setOnEdit={setOnEdit}
              setOnModerateLanguages={setOnModerateLanguages}
              setOnManagePermission={setOnManagePermission}
              setOnAddUser={setOnAddUser}
              setOnAddParticipant={setOnAddParticipant}
              setOnEnable={setOnEnable}
              setOnDisable={setOnDisable}
              setOnAdd={setOnAdd}
              setOnUpload={setOnUpload}
              setOnDups={setOnDups}
              setOnEditParticipantRanking={setOnEditParticipantRanking}
              setConfirmMarking={setConfirmMarking}
            />
          )
      )}
    </TableRow>
  );
};
const containerStyle = (width, maxWidth) => ({
  marginBlock: "2%",
  width: maxWidth || 0.89 * width,
});
const paperStyle = (width, maxWidth) => ({
  boxShadow: "0 0 3px #9E9E9E",
  minWidth: 400,
  maxWidth: 3200,
  width: (maxWidth || 0.89 * width) - 60,
});
const checkBoxDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: 60,
};
const selectAllCheckBoxStyle = {
  color: "#000",
  height: 61,
};
const checkBoxStyle = {
  color: "#000",
  height: 80,
};
const reasonWrapperStyle = {
  marginTop: 20,
  border: "1px solid",
  borderRadius: 12,
  padding: 20,
  backgroundColor: "#F2F2F2",
};
const firstRowStyle = {
  borderBottom: "1px solid #707070",
};
export default function HeavyTable({
  setOnVerfiy,
  setOnDups,
  headers,
  list,
  title,
  rowsPerPage,
  onChangePage,
  fixed = [],
  setIssingle,
  setOnReasons,
  isSelecting,
  setOnDelete,
  selecting,
  setSelecting,
  selected,
  setSelected,
  setId,
  setOnApprove,
  setOnReject,
  setOnRestore,
  setOnEdit,
  setOnModerateLanguages,
  setOnManagePermission,
  setOnAddUser,
  setOnAddParticipant,
  setOnEnable,
  setOnDisable,
  setOnAdd,
  setOnUpload,
  checkTask,
  taskInSection,
  collection,
  section,
  maxWidth,
  setPartcipient,
  setOnEditParticipantRanking,
  setConfirmMarking
}) {
  const [order, setOrder] = useState("asc");
  const [hasOverFlow, setHasOverFlow] = useState(false);
  const sort =
    title === "participants"
      ? "school_name"
      : title === "pending partners competition date"
      ? "id"
      : "status";
  const [orderBy, setOrderBy] = useState(sort);
  const [widths, setWidths] = useState(
    headers.map((h) => ({ id: h.id, width: 0, totalWidth: 0 }))
  );
  const [open, setOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [isScrolling, setIsScorlling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const getters = ["id", ...headers.map((h) => h.id)];
  const data = makeSureIsArray(list.data?list.data:list);
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = () => {
    let newSelected = [...selected];
    let newSelecting = [...selecting];
    // If not every row on the page is in the selected array, just push everything into the selected array,
    // don't worry about duplicate ids here, behind will handle
    // Else, remove every row from the page from the selected array
    if (
      !stableSort(data, getComparator(order, orderBy)).every((el) =>
        selected.includes(el.id)
      )
    ) {
      stableSort(data, getComparator(order, orderBy)).forEach((d) => {
        newSelected.push(d.id);
        newSelecting.push(d[getters[1]]);
      });
    } else {
      stableSort(data, getComparator(order, orderBy)).forEach((d) => {
        newSelected.splice(
          newSelected.findIndex((n) => n === d.id),
          1
        );
        newSelecting.splice(
          newSelecting.findIndex((n) => n === d[getters[1]]),
          1
        );
      });
    }
    // Handle duplicate ids here
    setSelected([...new Set(newSelected)]);
    setSelecting([...new Set(newSelecting)]);
  };
  const handleClick = (id, name) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newSelecting = [];
    if (selectedIndex === -1) {
      // If cannot find
      newSelected = newSelected.concat(selected, id);
      newSelecting = newSelecting.concat(selecting, name);
    } else if (selectedIndex === 0) {
      // If first
      newSelected = newSelected.concat(selected.slice(1));
      newSelecting = newSelecting.concat(selecting.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      // If last
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelecting = newSelecting.concat(selecting.slice(0, -1));
    } else if (selectedIndex > 0) {
      // If in between
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelecting = newSelecting.concat(
        selecting.slice(0, selectedIndex),
        selecting.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setSelecting(newSelecting);
  };
  const handleShift = (id) => {
    let sortedData = stableSort(data, getComparator(order, orderBy));
    // Find the earliest occurence in sortedData whr it is also in selected
    let firstToLast = sortedData
      .filter((s) => selected.includes(s.id))
      .map((s) => s.id);
    let [selectingIndex, firstIndex, lastIndex] = [
      sortedData.findIndex((d) => d.id === id),
      sortedData.findIndex((d) => d.id === firstToLast[0]),
      sortedData.findIndex((d) => d.id === firstToLast.slice(-1)[0]),
    ];
    if (selectingIndex >= firstIndex && selectingIndex <= lastIndex) {
      // If clicked between(inclusive of first and last), just check/uncheck the selected row
      // By right it should have its own set of behaviour(If u test by shift clicking around in file explorer)
      // But for now just leave it like this first because shift click is not required function
      handleClick(id, sortedData[selectingIndex][getters[1]]);
      return;
    }
    let newSelected = selected;
    let newSelecting = selecting;
    if (selectingIndex < firstIndex) {
      // If shift click from the front, check all until the last row
      for (let i = selectingIndex; i <= lastIndex; i++) {
        if (!newSelected.includes(sortedData[i].id)) {
          newSelected = [...newSelected, sortedData[i].id];
          newSelecting = [...newSelecting, sortedData[i][getters[1]]];
        }
      }
    } else if (selectingIndex > lastIndex) {
      // If shift click from the back, check all from start till selected row
      for (let i = firstIndex; i <= selectingIndex; i++) {
        if (!newSelected.includes(sortedData[i].id)) {
          newSelected = [...newSelected, sortedData[i].id];
          newSelecting = [...newSelecting, sortedData[i][getters[1]]];
        }
      }
    }
    setSelected(newSelected);
    setSelecting(newSelecting);
  };
  const handleSelectClick = (event, id, name) =>
    event.shiftKey && selected.length ? handleShift(id) : handleClick(id, name);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const onHoverRejectReason = (data) => {
    setOpen(true);
    setRejectReason(data);
  };
  const closeRejectReason = () => {
    setOpen(false);
    setRejectReason("");
  };
  const { width } = useWindowDimensions();
  const indeterminateCondition =
    selected.length > 0 && selected.length < data.length;
  const checkedCondition = data.length > 0 && selected.length === data.length;
  // const indeterminateCondition = selected.length > 0 && !data.every(el => selected.includes(el.id)) &&
  //     data.some(el => selected.includes(el.id))
  // const checkedCondition = selected.length > 0 && data.every(el => selected.includes(el.id))

  useEffect(() => {
    const contianer = document.getElementsByClassName("cont")[0];
    if (contianer.scrollWidth === contianer.offsetWidth) {
      setHasOverFlow(false);
    } else {
      setHasOverFlow(true);
    }
  }, [list]);
  const onMouseDown = (e) => {
    setIsScorlling(true);
    setClientX(e.clientX);
  };

  const onMouseUp = () => {
    setIsScorlling(false);
  };
  const onMouseMove = (e) => {
    if (isScrolling) {
      const contianer = document.getElementsByClassName("cont")[0];
      contianer.scrollLeft = scrollX + e.clientX - clientX;
      setScrollX(scrollX + e.clientX - clientX);
      setClientX(e.clientX);
    }
  };
  const ref = useRef(0);
  return (
    <Grid
      container
      alignItems="flex-start"
      style={containerStyle(width, maxWidth)}
    >
      {!isSelecting && title !== "compCollection" && (
        <div style={checkBoxDivStyle}>
          <Checkbox
            style={selectAllCheckBoxStyle}
            indeterminate={indeterminateCondition}
            checked={checkedCondition}
            onChange={() => handleSelectAllClick()}
          />
          {stableSort(data, getComparator(order, orderBy)).map((row, i) => (
            <Checkbox
              key={i}
              style={checkBoxStyle}
              onClick={(e) => handleSelectClick(e, row.id, row[getters[1]])}
              checked={isSelected(row.id)}
            />
          ))}
        </div>
      )}
      <Paper style={paperStyle(!isSelecting ? width : width + 60, maxWidth)}>
        <div style={{ position: "relative" }}>
          {hasOverFlow && (
            <BsFillArrowRightCircleFill
              style={{
                position: "absolute",
                fontSize: "50px",
                right: "-2%",
                top: "45%",
                zIndex: 9999,
                color: "#5E75C3",
              }}
              onClick={() => {
                const contianer = document.getElementsByClassName("cont")[0];
                let widthPerScroll = contianer.scrollWidth / headers.length;
                contianer.scrollLeft += widthPerScroll + 20;
              }}
            />
          )}
          <TableContainer
            className="cont"
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            style={{ outline: "none", userSelect: "none" }}
          >
            <Table size="small">
              <EnhancedTableHead
                handleRequestSort={handleRequestSort}
                headers={headers}
                widths={widths}
                setWidths={setWidths}
                fixed={fixed}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).map(
                  (row, i) => (
                    <Row
                      key={`${i}-${row.id}`}
                      getters={getters}
                      row={row}
                      isItemSelected={isSelected(row.id)}
                      setPartcipient={setPartcipient}
                      setIssingle={setIssingle}
                      setOnReasons={setOnReasons}
                      fixed={fixed}
                      widths={widths}
                      title={title}
                      setOnVerfiy={setOnVerfiy}
                      collection={collection}
                      section={section}
                      taskInSection={taskInSection}
                      checkTask={checkTask}
                      onHoverRejectReason={onHoverRejectReason}
                      list={list}
                      setId={setId}
                      setOnDelete={setOnDelete}
                      setOnApprove={setOnApprove}
                      setOnReject={setOnReject}
                      setOnRestore={setOnRestore}
                      setOnEdit={setOnEdit}
                      setOnModerateLanguages={setOnModerateLanguages}
                      setOnManagePermission={setOnManagePermission}
                      setOnAddUser={setOnAddUser}
                      setOnAddParticipant={setOnAddParticipant}
                      setOnEnable={setOnEnable}
                      setOnDisable={setOnDisable}
                      setOnAdd={setOnAdd}
                      setOnUpload={setOnUpload}
                      setOnDups={setOnDups}
                      setOnEditParticipantRanking={setOnEditParticipantRanking}
                      setConfirmMarking={setConfirmMarking}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedPagination
            list={list}
            rowsPerPage={rowsPerPage}
            onChangePage={onChangePage}
            title={title}
          />
        </div>
      </Paper>
      {/* {JSON.stringify({ selecting, selected })}<br /> */}
      <Modal open={open} onClose={() => closeRejectReason()} disableAutoFocus>
        <Box
          className="popUpModal horizontalScrollable"
          style={{
            width: 800,
            minHeight: 400,
            maxHeight: 600,
            display: "block",
            padding: 20,
          }}
        >
          <NunitoText
            value="Reason(s) for rejection"
            fontSize={30}
            fontWeight={600}
            color="#5E75C3"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: -50,
            }}
          >
            <IconButton
              onClick={() => closeRejectReason()}
              style={{ color: "#E83042" }}
            >
              <CancelIcon style={{ fontSize: 30 }} />
            </IconButton>
          </div>
          {rejectReason &&
            rejectReason.map((r, i) => (
              <div key={`${r.reject_id}-${i}`} style={reasonWrapperStyle}>
                <div style={firstRowStyle}>
                  <div style={centerFlexStyle}>
                    <NunitoText
                      value={`Rejected by: ${r.user.username}(${r.role.name})`}
                      fontSize={24}
                      fontWeight={300}
                      color="#5E75C3"
                      italic
                    />
                    <NunitoText
                      value={`On: ${r.created_by.slice(-20)}`}
                      fontSize={15}
                      fontWeight={300}
                      color="#F16774"
                      italic
                      marginLeft={10}
                    />
                  </div>
                </div>
                <NunitoText
                  value={r.reason}
                  fontSize={20}
                  marginTop={16}
                  align="left"
                />
              </div>
            ))}
        </Box>
      </Modal>
    </Grid>
  );
}
